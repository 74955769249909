import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import Header from "../components/Header.jsx"
import Footer from "../components/Footer.jsx"
import SEO from "../components/misc/SEO.jsx"
import Thanks from "../components/Thanks.jsx"

export default () => {
  return (
    <AnimationRevealPage disabled>
      <SEO title="Thankyou!" />
      <Header />
      <Thanks />
      <Footer />
    </AnimationRevealPage>
  )
}
