import React from "react"
import tw from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto my-6 lg:my-12 `
const HeadingInfoContainer = tw.div`flex flex-col items-center`
const HeadingTitle = tw(SectionHeading)`mt-4 text-4xl sm:text-5xl lg:text-6xl`
const Description = tw.div`text-base sm:text-xl my-8 font-bold `
const Tel = tw.a`text-center mt-8 text-xl inline-block`
const Information = tw.div`text-center mt-4 text-base`

export default () => {
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>
            <>Thanks!</>
          </HeadingTitle>
          <Description>
            <>
              お問い合わせを受け付けました｡
              <br />
              折り返し､担当者よりご連絡いたします｡
            </>
          </Description>
          <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
          <Information>
            <div>(サクッとAI､サクッとクラウド担当宛)</div>
            <div>営業時間平⽇9:30~18:30 (⼟⽇祝休み)</div>
          </Information>
        </HeadingInfoContainer>
      </Content>
    </Container>
  )
}
